import { RedocStandalone } from 'redoc';
import { useEffect, useState } from 'react'

function App() {
  const [result, setResult] = useState('')


  useEffect(() => {
    let resp = prompt('Password: ') ?? ''
    setResult(resp)
  }, [])
  
  if (result === 'wallbit_onramp') {
    return (
      // <RedocStandalone specUrl="https://redocly.github.io/redoc/openapi.yaml" />
      <RedocStandalone specUrl="./openapi.yaml" />
      // <RedocStandalone spec={main} />
    );
  } else {
    return(
      <div>
        <button onClick={() => window.location.reload()}>RELOAD</button>
      </div>
    )
  }
    
}

export default App;
